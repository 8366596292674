import React, { useEffect } from "react";
import CodeTemplate from "../../../Templetes/Categories/Details/Robomission/Robomission.template";
import SumoTemplate from "../../../Templetes/Categories/Details/Sumo/Sumo.template";

const SumoPage = () => {
  useEffect(() => {
    document.title = "We Do Challenge - Robo Guard Challenge";
  }, []);
  return <SumoTemplate />;
};

export default SumoPage;
